import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic08 from '../assets/images/lor2.jpg'
import pic09 from '../assets/imgs/landing/p1.jpg'

import pic10 from '../assets/imgs/landing/p2.jpg'
import pic11 from '../assets/imgs/landing/q1.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <p style ={{ textAlign: "justify" }}>UNIDANS is a complex project involving three European partners mainly focused on an
exchange of experience between partner countries with different insight in the
adapted/unified dance.
It will involve about 100 dance coaches and volunteers/country that will be trained in the
domain of adapted/unified dance and about 20 local NGOS/country in the domain of
social inclusion of people with disabilities and in the domain of dance/performing arts
that will participate to the local UNIDANS Networking Events.</p>

                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        
                        <div className="inner">
                            <header className="major">
                                <h3>Project Aim</h3>
                            </header>
                            <p>The project aim is to promote education in and through sport, with special focus on skills development of coaches that work in the domain of adapted/unified sport, in general and adapted/unified dance, in particular.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                 <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Objectives</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/objectives" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Activities</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/activities" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Who we address </h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/address" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
            </section>
        </div>

    </Layout>
)

export default Landing